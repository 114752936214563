





export interface ChoiceList {
  value: any;
  label: any;
}

export class VehicleDetails{ 
  mfgYear: number;
  odomrtBaseUom: ChoiceList;
  purpose: any;
  configCode: string;
  engineNum: number;
  description: string;
  active: string;
  purchasePartner: ChoiceList;
  purchasePrice: number;
  bPartner:ChoiceList;
  division: any;
  trimCode:ChoiceList;
  regNum: number;
  model:ChoiceList;
  currency:ChoiceList;
  vin: number;
  attachmentId: number;
  facility:any;
  make:ChoiceList;
  rta: ChoiceList;
  vehicleType: any;
  seatingCapacity: number;
  odomtrBaseUom:any;
  ownership:any;
  status:any;
  purchaseOrderRef:any;
  purchaseDate:any;
  vehicleValue:any;
  operator:Operator;
  vehicleName:string;
  fuelType:any;
  colour:any;
  purchasePriceF:any;
  vehicleId:any;
  
}
export interface Operator{
  label: any;
  personnelNumber: any;
}


