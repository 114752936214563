export class VehicleListConfig {
    type: string = 'all';
    config: {
        limit?: number,
        offset?: number,
        sort?: string,
        direction?: string,
        asOfDate?: string
    } = {};
    filter: FilterAttribute[] = [
        {
            type: "item",
            con: "or",
            attr: "regNum",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "seatingCapacity",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "vehicleName",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "type",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "vehicleModel",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "driver",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "assignee",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "dateFrom",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "dateTo",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "issueType",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "issuePurpose",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "dept",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "facilityName",
            operator: "like",
            value: "",
        },
        {
            type: "item",
            con: "or",
            attr: "jobTitle",
            operator: "like",
            value: "",
        },     
        {
            type: "item",
            con: "or",
            attr: "trimCode",
            operator: "like",
            value: "",
        },   
        {
            type: "item",
            con: "or",
            attr: "colour",
            operator: "like",
            value: "",
        }, 
        {
            type: "item",
            con: "or",
            attr: "partnerName",
            operator: "like",
            value: "",
        }, 

    ];

    getFilters(): string {
        return JSON.stringify(this.filter);
    }
}

export interface FilterAttribute {
    type: string;
    con: string;
    attr: string;
    operator: string;
    value: string;
}