import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddExternalComponent } from './add-external/add-external.component';
import { ExternalService } from "app/flm/flm-shared/_services/external.service";
import { SharedModule } from "app/shared";
import { LocationCreateComponent } from "app/flm/settings/location/location-create/location-create.component";
import { VendorSharedService } from 'app/flm/contact/vendor/services/vendor-shared.service';
import { VendorService } from 'app/flm/contact/vendor/services/vendor.service';
import { VendorCreateComponent } from "app/flm/flm-shared/vendor-create/vendor-create.component";
import { VehicleMakeCreateComponent } from 'app/flm/settings/vehicle-make/vehicle-make-create/vehicle-make-create.component';
import { VehicleMakeService } from 'app/flm/settings/vehicle-make/_services/vehicle-make.service';
import { VehicleMakeSharedService } from 'app/flm/settings/vehicle-make/_services/vehicle-make.shared.service';
import { RoutesCreateComponent } from 'app/flm/settings/route/routes-create/routes-create.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [AddExternalComponent, LocationCreateComponent, VendorCreateComponent, VehicleMakeCreateComponent, RoutesCreateComponent],
  entryComponents: [AddExternalComponent, LocationCreateComponent, VendorCreateComponent, VehicleMakeCreateComponent, RoutesCreateComponent],
  providers: [ExternalService,
    VehicleMakeService,
    VehicleMakeSharedService,
    VendorService,
    VendorSharedService]
})
export class FlmSharedModule { }
