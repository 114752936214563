import { ChoiceList } from './../../../leave/_models/leaves.interfaces';
export interface ListPerson {
  personId: number;
  personnelNumber: string;
  name: string;
  department: string;
  nationality: string;
  gender: string;
  emailId: string;
  status: string;
  statusCode: string;
  color: string;
  facility: string;
  jobTitle: string;
}
export interface ChoiceListWithSixAttr {
  value1: number;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
}
export class Person {
  personId: number;
  effectiveStartDate;
  effectiveEndDate;
  lastUpdateDate: any;
  lastUpdatedBy: number;
  candidateFlag: number;
  company: number;
  division: number;
  facility: number;
  createdBy: number;
  creationDate: number;
  personalDetails: PersonalDetails;
  birthAndOrigin: BirthAndOrigin;
  otherInfo: OtherInfo;
  employmentDetails: EmploymentDetails;
}

export class PersonalDetails {
  personnelNumber: string;
  personalTitle: ChoiceList;
  firstName: string;
  middleName: string;
  lastName: string;
  knownAs: string;
  gender: ChoiceList;
  statusJSON: any;
  emailId: string;
  status: any;
  statusColor: string;
  personType : ChoiceList;
}

export interface BirthAndOrigin {
  nationality: ChoiceList;
  eatingHabit: ChoiceList;
  nearestAirport: ChoiceList;
  dob: any;
  countryOfBirth: ChoiceList;
  ethnicity: ChoiceList;
  motherTongue: ChoiceList;
  bloodGroup: ChoiceList;
  religion: ChoiceList;
  expatriate: string;
  nameAsInPassport: string;
  nationalIdNumber: string;
}

export interface EmploymentDetails {
  status: string;
  personClassification: ChoiceList;
  personCategory: ChoiceList;
  firstHireDate: any;
  dateOfJoin: string;
  terminationDate: any;
  lastDateWorked: string;
  terminationReason: ChoiceList;
}

export interface OtherInfo {
  personWithDisability: string;
  typeOfDisability: string;
  maritalStatus: ChoiceList;
  deceasedDate: any;
  marriedDate: any;
  preferredLanguage: any;
}

export interface ChoiceList {
  value: any;
  label: any;
}

export class NewPerson {
  personnelNumber: string;
  effectiveStartDate;
  firstName: string;
  lastName: string;
  dob: any;
  nationality: string;
  knownAs: string;
  maritalStatus: string;
  gender: string;
}

export interface NewPersonResponse {
  personId: number;
  effectiveStartDate;
}

export class EmergencyContactsList {
  contactId: number;
  personId: number;
  contactPerson: string;
  relationship: string;
  phone1: string;
  phone2: string;
  remarks: string;
}

export class ContactPoints {
  contactPointId: number;
  contactPointType: string;
  ownerTablePk: number;
  primaryFlag: string;
  emailAddress: string;
  phoneAreaCode: string;
  phoneCountryCode: any;
  phoneNumber: string;
  phoneExtension: string;
  phoneLineType: string;
  webType: string;
  url: string;
  rawPhoneNumber: string;
  contactByPurpose: string;
  primaryByPurpose: string;
}
export class Address {
  personAddressId: number;
  addressId: number;
  personId: number;
  addressType: string;
  addressCategory: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  country: ChoiceList;
  postalCode: string;
}

//CV data
export class Experience {
  expId: number;
  personId: number;
  employer: ChoiceList;
  jobTitle: ChoiceList;
  location: string;
  internFlag: string;
  fromMonth: number;
  fromYear: number;
  toMonth: number;
  toYear: number;
  expInMonths: number;
  currentEmployer: string;
  designationOnJoining: ChoiceList;
  designationOnLeaving: ChoiceList;
  reasonForLeaving: string;
  jobDescription: string;
  ref1ContactPerson: string;
  ref1Designation: string;
  ref1WorkEmail: string;
  ref1WorkPhone: string;
  ref2ContactPerson: string;
  ref2Designation: string;
  ref2WorkEmail: string;
  ref2WorkPhone: string;
  ctc: number;
  ctcCurrency: number;
}
export class Education {
  personEducationId: number;
  personId: number;
  displayOrder: number;
  institution: ChoiceList;
  attendedFrom: number;
  attendedTo: number;
  yearOfPassing: number;
  degree: ChoiceList;
  fieldOfStudy: ChoiceList;
  grade: string;
  activities: string;
  description: string;
  remarks: string;
  pursuing: string;
}
export class LanguageProficiencyList {
  personLanguageId: number;
  language: ChoiceList;
  read: string;
  write: string;
  speak: string;
  motherTongue: string;
}
export class SkillList {
  personSkillId: number;
  skill: ChoiceList;
  rating: number;
}

export class Skill {
  skillId: number;
  skill: string;
  description: number;
}

export class Organization {
  extOrgId: number;
  orgType: string;
  orgName: string;
  description: string;
  location: string;
  address: string;
  url: string;
  email: string;
  contactPerson: string;
  phone1: string;
  regNum: string;
  yearOfEstablishment: number;
}

export class Degree {
  degreeId: number;
  degreeName: string;
  description: string;
  shortCode: string;
  isDegree: string;
}
export class JobTitle {
  jobTitleId: number;
  personId: number;
  jobTitle: string;
  noticePeriod: number;
  probationLength: number;
}

//dependent Data
export class Dependents {
  memberId: number;
  personId: number;
  active: string;
  dob: any;
  gender: string;
  genderCode: string;
  guardianAddress: string;
  guardianContact: string;
  guardianName: string;
  isDependant: boolean;
  isMinor: boolean;
  isStudent: boolean;
  memberName: string;
  memberPersonId: string;
  personnelNumber: string;
  nationality: ChoiceList;
  occupation: string;
  relationship: string;
  relationshipCode: string;
}
export class Assignment {
  primary: boolean;
  assignmentId: number;
  personId: number;
  effectiveStartDate;
  effectiveEndDate;
  internalAddressLine: string;
  grade: ChoiceList;
  job: ChoiceList;
  position: ChoiceList;
  supervisor: ChoiceList;
  facility: ChoiceList;
  department: ChoiceList;
  dateProbationEnd: any;
  probationStartDate: any;
  employerQty: string;
  employerUnitOfNotice: string;
  company: string;
  division: string;
  createdBy: string;
  bankAccount: ChoiceListWithSixAttr;
  paycycle: ChoiceList;
  paymentMethod: ChoiceList;

  creationDate: any;
  lastUpdateDate: any;
  lastUpdatedBy: string;
  workCenter: ChoiceList;
  weekendPolicy: ChoiceList;
  leavePolicy: ChoiceList;
}
export class Documents {
         attachmentStatus: ChoiceList;
         color: string;
         attachmentId: string;
         attachmentType: ChoiceList;
         documentNo: string;
         issueDate: any;
         expiryDate: any;
         placeOfIssue: string;
         immigrationNumber: string;
         countryOfIssue: ChoiceList;
         visaType: string;
         visaDesignation: string;
         visaEntryType: string;
         sponsor: string;
         ppName: string;
         ppEcnrRequired: boolean;
         ppDob: any;
         ppType: string;
         issueAuthority: string;
         remarks: string;
         active: string;
         objectOwner: string;
         objectName: string;
         objectPk: string;
         personId: string;
       }
export class DocumentType {
  attachmentTypeId: number = 1;
  attachmentTypeName: string;
  shortCode: string;
  parentType: ChoiceList;
}
// Referral
export class ReferralList {
  referralId: number;
  agency: any;
  active: string;
  agencyCode: string;
  agencyName: any;
  person: any;
  referralCode: string;
  referredBy: string;
  referredPersonId: number;
  remarks: string;
}
export class ReferralSubmit {
  referralId: number;
  active: string;
  agencyCode: string;
  agencyName: any;
  facility: number;
  personId: number;
  referralCode: string;
  referredBy: string;
  referredPersonId: number;
  remarks: string;
}
export class BranchDetails {
  value1: number;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
}
export class NewBankAccount {
  accountClassification: ChoiceList;
  description: string;
  accountHolder: ChoiceList;
  accountName: string;
  accountNum: string;
  accountOwnerObject: string;
  accountType: ChoiceList;
  bank: ChoiceList;
  branch: ChoiceList;
  currency: ChoiceList;
  iban: string;
  ownBpEmp: ChoiceList;
  branchDetails: BranchDetails;

}
