
import {map} from 'rxjs/operators';
import { FleetApiService, UtilityService } from "app/shared";
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {HttpParams} from "@angular/common/http";
import { Observable } from 'rxjs';
import { VehicleListConfig, VehicleList } from "app/flm/vehicle/vehicle-assignment/_models";

@Injectable()
export class VehicleAssignService {
    private asOfDate;
    private token;
    constructor(
        private apiService: FleetApiService,
        private utilityService: UtilityService,

    ) { }

    getVehicleList(config: VehicleListConfig, ownership): Observable<{ count: number, vehicleAssign: VehicleList[] }> {
        this.token = Math.random();
        let params: HttpParams = new HttpParams();
        Object.keys(config.config)
            .forEach((key) => {
                params = params.set(key, config.config[key]);
            });
        params = params.set("filter", config.getFilters());

        if (!ownership || ownership == 'ALL' || ownership == '') {
            var apiString = '/vehicle/assignments';
        }
        else {
            var apiString = '/vehicle/assignments?ownership=' + ownership;
        }

        return this.apiService.get(
            apiString,
            params, this.token).pipe(map(data => {

                data.skipRequest = this.token != data.token;
                return data;
            }));
    }

    getVehicleDetails(vehicleId): Observable<VehicleList> {
        let params: HttpParams = new HttpParams();
        return this.apiService.get('/vehicle/assignments/' + vehicleId, params).pipe(
            map(data => data.vehicleAssign));
    }

    updateVehicleAssignment(vehicleAssign): Observable<any> {
        return this.apiService.put('/vehicle/assignments/' + vehicleAssign.assignmentId, { vehicleAssign: vehicleAssign }).pipe(
            map(data => data));
    }

    createVehicleAssignment(vehicleAssign): Observable<any> {
        return this.apiService.post('/vehicle/assignments', { vehicleAssign: vehicleAssign }).pipe(
            map(data => data));
    }

}
